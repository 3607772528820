<template>
  <div id="projects-container" class="pa-10 mt-6">
    <div id="projects">
      <v-row justify="center">
        <v-col cols="11" md="10" lg="8" xl="6">
          <div
            class="
              text-body-1 text-md-h4 text--disabled text-center
              font-weight-light
            "
          >
            Here are some of the projects I have worked on
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" style="margin-bottom: 10rem" id="projects-items">
        <v-col
          cols="12"
          md="6"
          lg="4"
          xl="4"
          v-for="(item, i) in projects"
          :key="i"
        >
          <Project
            :image="item.image"
            :title="item.title"
            :text="item.text"
            :url="item.url"
          ></Project>
        </v-col>
      </v-row>
    </div>
    <v-img
      :src="background"
      height="500"
      max-height="500"
      width="500"
      max-width="500"
      id="background-image"
      contain
    ></v-img>
  </div>
</template>

<script>
import Project from './project.vue'
const background = require('@/assets/images/podium-background.png')
const projects = [
  {
    image: require('@/assets/images/wardah.png'),
    title: 'Wardah tec',
    text: 'Introducing the Revamped Website: A Fast, Crisp, and Elegant Online Experience. carefully designed and optimized to provide users with an exceptional online journey. I worked along the team to enhance every aspect of the company website, resulting in a seamless, lightning-fast, and visually stunning browsing experience',
    url: 'https://wardeh-876f9.web.app/en/home',
  },
  {
    image: require('@/assets/images/dere.png'),
    title: "Al Dere'",
    text: "Improve the code base and functionality of the e-commerce web app by refactoring and optimizing the code for better performance and improved UX. I've optimized the performance of the content system and web app through regular code refactoring and optimization",
    url: 'https://sa.al-daraa.com/home',
  },
  {
    image: require('@/assets/images/100y.png'),
    title: 'Meih Yawmeyie',
    text: 'An app for daily contests, designed to engage users and encourage participation. The app features interactive elements that keep users engaged and coming back for more. Winners are selected randomly based on user performance, and prizes are awarded by the company',
    url: 'http://bit.ly/3k6Xlau',
  },
  {
    image: require('@/assets/images/hadethni.png'),
    title: 'Hadethni',
    text: 'chat application that enables users to initiate conversations with their friends or connect with random people for casual chats. The app offers a user-friendly interface and features that make it easy to find and connect with people from around the world',
    url: 'http://bit.ly/3GVAV4Y',
  },
  {
    image: require('@/assets/images/omlati.png'),
    title: 'Omlati',
    text: 'sleek and efficient currency exchange app that allows users to quickly access up-to-date currency prices and exchange information. With Omlati, users can easily track exchange rates, compare prices, and make informed decisions about their financial transactions',
    url: 'http://bit.ly/3GWGP63',
  },
  {
    image: require('@/assets/images/magma-v1.png'),
    title: 'Magma',
    text: "The Magma website serves as a platform to communicate our company's vision, showcase our team members, and provide contact information. Our website is designed to be user-friendly and visually appealing, with easy navigation and clear messaging",
    url: 'https://bit.ly/3uDPLX3',
  },
  {
    image: require('@/assets/images/magma-v2.png'),
    title: 'Magma v2',
    text: "Updated version of Magama website. Visitors can learn about our company's mission and values, explore our team member profiles, and get in touch with us through our contact page. We believe that our website reflects the same level of quality and professionalism that we bring to all of our business endeavors",
    url: 'https://bit.ly/3PgcXEd',
  },
  {
    image: require('@/assets/images/dev-life.png'),
    title: 'Dev life',
    text: 'Dev Life is a website dedicated to providing developers with helpful tips and insights in a fun and interactive way. Our website features animated and interactive content that demonstrates key development concepts and best practices',
    url: 'https://dev-life-b059b.web.app',
  },
  {
    image: require('@/assets/images/we-learn.png'),
    title: 'We Learn',
    text: "A simple and user-friendly website designed to showcase a learning platform's front-end capabilities. The website features clean and modern design, easy navigation, and engaging content that highlights the platform's features and benefits",
    url: 'https://bit.ly/3tbkvit ',
  },
  {
    image: require('@/assets/images/portfolio.png'),
    title: 'My website',
    text: 'showcase my web development experience and skills in a visually appealing and user-friendly way. The website features elegant geometric shapes and high-quality navigation UX to create an engaging and immersive experience for visitors',
    url: 'https://bit.ly/3qd5RWg',
  },
  {
    image: require('@/assets/images/inmaa.png'),
    title: 'Inmaa',
    text: "The Inmaa website is designed to showcase our HVAC company's services and capabilities, with a focus on convenience and ease of use. We offer a web app that allows customers to log in and check on their service status, making it easy to track progress and stay informed",
    url: 'https://alinmaone.com/home',
  },
  {
    image: require('@/assets/images/feedme.png'),
    title: 'Feed me',
    text: 'Food order app that provides users with a range of convenient services, including delivery, ordering, and reservation. With its intuitive interface and advanced features, Feed Me makes it easy to find your favorite restaurants, browse menus, place orders, and reserve tables',
    url: '',
  },
  {
    image: require('@/assets/images/hophop.png'),
    title: 'Hop Hop',
    text: 'Ride app that enables users to order shared rides with ease and convenience. With its user-friendly interface and advanced features, Hop Hop makes it easy to find a ride, choose your destination, and split the fare with other riders',
    url: '',
  },
  {
    image: require('@/assets/images/akhbar.png'),
    title: 'Akhbar',
    text: 'News website for local and global news that follows SEO practices and provides a minimal user experience. The content is managed by a CMS system that provides a convenient way to supply data to an API for mobile users and server-side rendering that serve the website for web clients',
    url: 'https://www.akhbartr.com',
  },
]

export default {
  name: 'projects',

  components: {
    Project,
  },

  data() {
    return {
      projects,
      background,
    }
  },

  methods: {
    animate() {
      this.gsap.from('#projects-items .project-card', {
        y: 75,
        opacity: 0,
        stagger: {
          each: 0.8,
          grid: 'auto',
          from: 'left',
          ease: 'power2.in',
        },
      })
    },
  },

  metaInfo() {
    return {
      title: 'Projects',
      titleTemplate: '%s - Wael Dev',
      meta: [
        {
          vmid: 'description',
          property: 'description',
          content:
            'Discover the latest projects crafted by a skilled web developer. My portfolio showcases a wide range of stunning and functional web applications and websites designed to meet the unique needs.',
        },
        {
          vmid: 'language',
          property: 'language',
          content: 'en-EN',
        },
      ],
    }
  },

  mounted() {
    this.animate()
  },
}
</script>

<style>
#projects-container {
  min-height: 100vh;
  position: relative;
}

#projects * {
  z-index: 2;
}

#background-image {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.5;
  z-index: 1;
}
</style>
