<template>
  <div>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 1366 768"
      enable-background="new 0 0 1366 768"
      xml:space="preserve"
    >
      <g>
        <linearGradient
          id="group-15_1_"
          gradientUnits="userSpaceOnUse"
          x1="408.334"
          y1="678.8877"
          x2="520.374"
          y2="678.8877"
        >
          <stop offset="0" style="stop-color: #94c0e9" />
          <stop offset="0.008" style="stop-color: #8bc1e9" />
          <stop offset="0.0212" style="stop-color: #71c5e8" />
          <stop offset="0.0323" style="stop-color: #57c9e8" />
          <stop offset="0.3186" style="stop-color: #4c9ed1" />
          <stop offset="0.6074" style="stop-color: #437bbd" />
          <stop offset="0.8431" style="stop-color: #3d64b1" />
          <stop offset="0.9944" style="stop-color: #3b5cad" />
        </linearGradient>
        <circle
          id="group-15"
          fill="url(#group-15_1_)"
          cx="464.354"
          cy="678.889"
          r="56.02"
        />
        <g id="group-14">
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="606.1357"
            y1="585.6758"
            x2="553.5054"
            y2="500.6576"
          >
            <stop offset="0" style="stop-color: #ffffff" />
            <stop offset="1" style="stop-color: #000000" />
          </linearGradient>
          <polygon
            opacity="0.1"
            fill="url(#SVGID_1_)"
            points="387.004,556.749 417.368,699.93 794.76,471.189 633.125,392.244 			"
          />
          <g>
            <polygon
              fill="#EE97C2"
              points="494.457,102.321 347.091,206.703 354.258,402.979 309.055,491.298 324.399,596.445 
					633.125,316.738 				"
            />
            <linearGradient
              id="SVGID_2_"
              gradientUnits="userSpaceOnUse"
              x1="309.0547"
              y1="349.3833"
              x2="552.1484"
              y2="349.3833"
            >
              <stop offset="0" style="stop-color: #ffffff" />
              <stop
                offset="0.9898"
                style="stop-color: #ffffff; stop-opacity: 0"
              />
            </linearGradient>
            <polygon
              opacity="0.7"
              fill="url(#SVGID_2_)"
              points="494.457,102.321 347.091,206.703 354.258,402.979 309.055,491.298 
					324.399,596.445 552.148,191.528 				"
            />
            <polygon
              fill="#39B1A7"
              points="336.698,680.723 792.838,338.997 633.125,316.738 324.399,596.445 				"
            />

            <linearGradient
              id="SVGID_3_"
              gradientUnits="userSpaceOnUse"
              x1="504.626"
              y1="383.3359"
              x2="765.708"
              y2="383.3359"
              gradientTransform="matrix(0.9895 -0.1444 0.1444 0.9895 -40.2039 -66.9334)"
            >
              <stop offset="0" style="stop-color: #f38b00" />
              <stop offset="0.1411" style="stop-color: #f59400" />
              <stop offset="0.3749" style="stop-color: #faad00" />
              <stop offset="0.5674" style="stop-color: #ffc700" />
              <stop offset="0.9944" style="stop-color: #f9e0a4" />
            </linearGradient>
            <polygon
              fill="url(#SVGID_3_)"
              points="494.457,102.321 633.125,316.738 792.838,338.997 575.077,150.214 				"
            />
          </g>
        </g>
        <g id="group-13">
          <linearGradient
            id="SVGID_4_"
            gradientUnits="userSpaceOnUse"
            x1="781.3779"
            y1="470.4756"
            x2="921.1416"
            y2="340.8399"
          >
            <stop offset="0" style="stop-color: #ffffff" />
            <stop offset="1" style="stop-color: #000000" />
          </linearGradient>
          <polygon
            opacity="0.1"
            fill="url(#SVGID_4_)"
            points="735.408,342.531 745.781,430.301 900.944,471.322 1060,382.629 
				1060,219.677 			"
          />
          <g>
            <linearGradient
              id="SVGID_5_"
              gradientUnits="userSpaceOnUse"
              x1="894.0078"
              y1="200.1846"
              x2="1140.5723"
              y2="200.1846"
            >
              <stop offset="0" style="stop-color: #f38b00" />
              <stop offset="0.1411" style="stop-color: #f59400" />
              <stop offset="0.3749" style="stop-color: #faad00" />
              <stop offset="0.5674" style="stop-color: #ffc700" />
              <stop offset="0.9944" style="stop-color: #f9e0a4" />
            </linearGradient>
            <polygon
              fill="url(#SVGID_5_)"
              points="908.248,113.91 894.008,227.268 1026.108,307.933 1140.572,251.925 1073.785,92.436 				
					"
            />
            <polygon
              fill="#E685B3"
              points="675.966,340.625 908.269,219.854 1082.705,280.24 846.103,396.012 				"
            />
            <polygon
              fill="#39B1A7"
              points="908,219.677 908,113.91 606.152,151.358 675.779,340.625 				"
            />
            <linearGradient
              id="SVGID_6_"
              gradientUnits="userSpaceOnUse"
              x1="606.1523"
              y1="189.2378"
              x2="908"
              y2="189.2378"
            >
              <stop offset="0" style="stop-color: #ffffff" />
              <stop
                offset="0.9898"
                style="stop-color: #ffffff; stop-opacity: 0"
              />
            </linearGradient>
            <polygon
              opacity="0.7"
              fill="url(#SVGID_6_)"
              points="908,151.358 908,113.91 606.152,151.358 647.775,264.566 				"
            />
          </g>
        </g>
        <g id="group-12">
          <polygon
            fill="#39B1A7"
            points="517.39,652.863 561.754,456.41 454.129,485.896 468.56,677.784 			"
          />
          <polyline
            fill="#C96FA2"
            points="413.614,713.314 365.441,579.737 454.166,486.425 468.497,677.725 424.713,723.774 			"
          />

          <linearGradient
            id="SVGID_7_"
            gradientUnits="userSpaceOnUse"
            x1="275.1226"
            y1="1253.5859"
            x2="407.541"
            y2="1253.5859"
            gradientTransform="matrix(0.5395 -0.842 0.842 0.5395 -827.8688 247.8958)"
          >
            <stop offset="0" style="stop-color: #ffffff" />
            <stop
              offset="0.9898"
              style="stop-color: #ffffff; stop-opacity: 0"
            />
          </linearGradient>
          <polyline
            opacity="0.7"
            fill="url(#SVGID_7_)"
            points="413.614,713.314 365.441,579.737 395.702,547.912 468.497,677.725 
				424.713,723.774 			"
          />
        </g>
        <g id="group-11">
          <linearGradient
            id="SVGID_8_"
            gradientUnits="userSpaceOnUse"
            x1="426.0908"
            y1="617.6289"
            x2="626.8779"
            y2="617.6289"
          >
            <stop offset="0" style="stop-color: #ffffff" />
            <stop offset="1" style="stop-color: #000000" />
          </linearGradient>
          <polygon
            opacity="0.2"
            fill="url(#SVGID_8_)"
            points="454.816,585.142 426.091,700.942 539.8,703.847 626.878,531.411 			"
          />
          <g>
            <polygon
              fill="#C96FA2"
              points="508.628,700.942 575.414,456.655 906.313,296.615 936.671,361.382 				"
            />
            <linearGradient
              id="SVGID_9_"
              gradientUnits="userSpaceOnUse"
              x1="542.5586"
              y1="436.7246"
              x2="906.3135"
              y2="436.7246"
            >
              <stop offset="0" style="stop-color: #ffffff" />
              <stop
                offset="0.9898"
                style="stop-color: #ffffff; stop-opacity: 0"
              />
            </linearGradient>
            <polygon
              opacity="0.7"
              fill="url(#SVGID_9_)"
              points="542.559,576.834 575.414,456.655 906.313,296.615 825.254,366.182 				"
            />
            <linearGradient
              id="SVGID_10_"
              gradientUnits="userSpaceOnUse"
              x1="508.6279"
              y1="546.8145"
              x2="1015.5332"
              y2="546.8145"
            >
              <stop offset="0" style="stop-color: #f38b00" />
              <stop offset="0.1411" style="stop-color: #f59400" />
              <stop offset="0.3749" style="stop-color: #faad00" />
              <stop offset="0.5674" style="stop-color: #ffc700" />
              <stop offset="0.9944" style="stop-color: #f9e0a4" />
            </linearGradient>
            <polygon
              fill="url(#SVGID_10_)"
              points="1015.533,361 936.588,361 508.628,700.756 851.669,732.63 				"
            />
            <polygon
              fill="#EE97C2"
              points="1079.352,717.73 851.669,732.91 1015.533,361.374 1066.646,435.765 				"
            />
            <polygon
              fill="#39B1A7"
              points="906.225,297 1067,297 1067,435.765 1015.533,361 936.588,361 				"
            />
          </g>
        </g>
        <g id="group-10">
          <path
            fill="#2A1F1D"
            d="M815.129,671.643c-39.655,0-71.916-32.262-71.916-71.917s32.261-71.917,71.916-71.917
				s71.917,32.262,71.917,71.917S854.784,671.643,815.129,671.643z M815.129,529.327c-38.818,0-70.398,31.58-70.398,70.398
				s31.58,70.399,70.398,70.399s70.399-31.581,70.399-70.399S853.947,529.327,815.129,529.327z"
          />
        </g>
        <circle
          id="group-09"
          fill="#E685B3"
          cx="408.488"
          cy="678.834"
          r="25.479"
        />
        <g id="group-08">
          <path
            fill="#2A1F1D"
            d="M250.913,507.294c-11.837,0-21.468-9.631-21.468-21.468c0-11.838,9.631-21.468,21.468-21.468
				c11.837,0,21.468,9.63,21.468,21.468C272.381,497.663,262.75,507.294,250.913,507.294z M250.913,465.876
				c-11,0-19.949,8.949-19.949,19.95c0,11,8.949,19.949,19.949,19.949c11,0,19.95-8.949,19.95-19.949
				C270.863,474.825,261.914,465.876,250.913,465.876z"
          />
        </g>
        <g id="group-07">
          <path
            fill="#2A1F1D"
            d="M407.113,36.916c-11.193,0-20.299-9.106-20.299-20.299s9.105-20.299,20.299-20.299
				s20.299,9.106,20.299,20.299S418.307,36.916,407.113,36.916z M407.113-2.164c-10.355,0-18.781,8.425-18.781,18.781
				c0,10.356,8.426,18.781,18.781,18.781s18.781-8.425,18.781-18.781C425.895,6.262,417.469-2.164,407.113-2.164z"
          />
        </g>
        <linearGradient
          id="group-06_1_"
          gradientUnits="userSpaceOnUse"
          x1="1007.4365"
          y1="311.3945"
          x2="1075.1816"
          y2="311.3945"
        >
          <stop offset="0" style="stop-color: #94c0e9" />
          <stop offset="0.008" style="stop-color: #8bc1e9" />
          <stop offset="0.0212" style="stop-color: #71c5e8" />
          <stop offset="0.0323" style="stop-color: #57c9e8" />
          <stop offset="0.3186" style="stop-color: #4c9ed1" />
          <stop offset="0.6074" style="stop-color: #437bbd" />
          <stop offset="0.8431" style="stop-color: #3d64b1" />
          <stop offset="0.9944" style="stop-color: #3b5cad" />
        </linearGradient>
        <circle
          id="group-06"
          fill="url(#group-06_1_)"
          cx="1041.309"
          cy="311.395"
          r="33.873"
        />
        <linearGradient
          id="group-05_1_"
          gradientUnits="userSpaceOnUse"
          x1="11"
          y1="-28.9541"
          x2="156.438"
          y2="-28.9541"
        >
          <stop offset="0" style="stop-color: #94c0e9" />
          <stop offset="0.008" style="stop-color: #8bc1e9" />
          <stop offset="0.0212" style="stop-color: #71c5e8" />
          <stop offset="0.0323" style="stop-color: #57c9e8" />
          <stop offset="0.3186" style="stop-color: #4c9ed1" />
          <stop offset="0.6074" style="stop-color: #437bbd" />
          <stop offset="0.8431" style="stop-color: #3d64b1" />
          <stop offset="0.9944" style="stop-color: #3b5cad" />
        </linearGradient>
        <circle
          id="group-05"
          fill="url(#group-05_1_)"
          cx="83.719"
          cy="-28.954"
          r="72.719"
        />
        <circle
          id="group-04"
          fill="#39B1A7"
          cx="855.186"
          cy="218.738"
          r="8.677"
        />
        <g id="group-03">
          <path
            fill="#2A1F1D"
            d="M1317.126,152.117c-27.776,0-50.374-22.598-50.374-50.374c0-27.776,22.598-50.374,50.374-50.374
				s50.374,22.598,50.374,50.374C1367.5,129.52,1344.902,152.117,1317.126,152.117z M1317.126,52.887
				c-26.939,0-48.855,21.917-48.855,48.856c0,26.939,21.916,48.856,48.855,48.856s48.856-21.917,48.856-48.856
				C1365.982,74.804,1344.065,52.887,1317.126,52.887z"
          />
        </g>
        <circle
          id="group-02"
          fill="#EE97C2"
          cx="707.126"
          cy="258.094"
          r="20.953"
        />
        <g id="group-01">
          <linearGradient
            id="SVGID_11_"
            gradientUnits="userSpaceOnUse"
            x1="992.7656"
            y1="844.2266"
            x2="1204.6797"
            y2="844.2266"
          >
            <stop offset="0" style="stop-color: #94c0e9" />
            <stop offset="0.008" style="stop-color: #8bc1e9" />
            <stop offset="0.0212" style="stop-color: #71c5e8" />
            <stop offset="0.0323" style="stop-color: #57c9e8" />
            <stop offset="0.3186" style="stop-color: #4c9ed1" />
            <stop offset="0.6074" style="stop-color: #437bbd" />
            <stop offset="0.8431" style="stop-color: #3d64b1" />
            <stop offset="0.9944" style="stop-color: #3b5cad" />
          </linearGradient>
          <path
            fill="url(#SVGID_11_)"
            d="M1204.68,844.229c0,27.211-10.258,52.018-27.109,70.783
				c-19.397,21.588-47.54,35.171-78.85,35.171c-58.517,0-105.955-47.438-105.955-105.954c0-37.438,19.418-70.343,48.729-89.188
				c16.503-10.612,36.144-16.771,57.227-16.771C1157.241,738.27,1204.68,785.707,1204.68,844.229z"
          />
          <linearGradient
            id="SVGID_12_"
            gradientUnits="userSpaceOnUse"
            x1="1159.665"
            y1="763.3545"
            x2="1105.0101"
            y2="842.3002"
          >
            <stop offset="0" style="stop-color: #94c0e9" />
            <stop offset="0.008" style="stop-color: #8bc1e9" />
            <stop offset="0.0212" style="stop-color: #71c5e8" />
            <stop offset="0.0323" style="stop-color: #57c9e8" />
            <stop offset="0.3186" style="stop-color: #4c9ed1" />
            <stop offset="0.6074" style="stop-color: #437bbd" />
            <stop offset="0.8431" style="stop-color: #3d64b1" />
            <stop offset="0.9944" style="stop-color: #3b5cad" />
          </linearGradient>
          <path
            fill="url(#SVGID_12_)"
            d="M1204.68,844.229c0,27.211-10.258,52.018-27.109,70.783L1041.494,755.04
				c16.503-10.612,36.144-16.771,57.227-16.771C1157.241,738.27,1204.68,785.707,1204.68,844.229z"
          />
        </g>
      </g>
    </svg>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'background',

  data() {
    return {
      //
    }
  },

  methods: {
    animate() {
      this.gsap.to('#group-11', {
        xPercent: 60,
        yPercent: 5,
        scale: 0.5,
        duration: 3,
        rotation: 40,
        transformOrigin: '50% 50%',
        ease: 'power2.out',
      })
      this.gsap.to('#group-12', {
        xPercent: -50,
        yPercent: 2,
        scale: 1.1,
        duration: 2,
        rotation: 90,
        transformOrigin: '50% 50%',
        ease: 'power2.out',
      })
      this.gsap.to('#group-13', {
        xPercent: 50,
        yPercent: -40,
        scale: 0.8,
        duration: 2,
        rotation: 40,
        transformOrigin: '50% 50%',
        ease: 'power2.out',
      })
      this.gsap.to('#group-14', {
        xPercent: -70,
        yPercent: -40,
        scale: 0.8,
        duration: 2,
        ease: 'power2.out',
      })
      this.gsap.to('#group-15', {
        x: -300,
        yPercent: 10,
        duration: 2,
        ease: 'power2.out',
      })
      this.gsap.to('#group-15', {
        rotation: 360,
        transformOrigin: '50% 50%',
        duration: 5,
        repeat: -1,
        yoyo: true,
      })
      this.gsap.to('#group-01', {
        y: -300,
        yPercent: 10,
        duration: 2,
        scale: 0.5,
      })
      this.gsap.to('#group-01', {
        rotation: 360,
        transformOrigin: '50% 50%',
        duration: 6,
        repeat: -1,
        yoyo: true,
      })
      this.gsap.to('#group-03', {
        y: 150,
        scale: 1.3,
        transformOrigin: '50% 50%',
        duration: 1,
      })
      this.gsap.to('#group-02', {
        y: -100,
        transformOrigin: '50% 50%',
        duration: 2,
      })
      this.gsap.to('#group-02', {
        scale: 1.3,
        transformOrigin: '50% 50%',
        duration: 1,
        repeat: -1,
        yoyo: true,
      })
      this.gsap.to('#group-08', {
        rotation: 360,
        duration: 8,
        repeat: -1,
        ease: 'none',
      })
      this.gsap.to('#group-09', {
        x: 300,
        duration: 0.5,
      })
      this.gsap.to('#group-05', {
        rotation: 360,
        duration: 8,
        repeat: -1,
        ease: 'none',
      })
      this.gsap.to('#group-06', {
        rotation: 360,
        duration: 8,
        repeat: -1,
        ease: 'none',
      })
      this.gsap.to('#group-07', {
        x: 300,
        duration: 8,
        repeat: -1,
        yoyo: true,
        ease: 'none',
      })
    },
    animateHoverShapes() {
      const animateCard = (selector) => {
        const hoverTL = this.gsap.timeline({ paused: true })
        hoverTL.to(selector, {
          delay: 0.2,
          opacity: 0.5,
          duration: 1,
          rotate: 2,
        })

        return hoverTL
      }

      const shapes = document.querySelectorAll(
        '#group-11, #group-12, #group-14'
      )

      shapes.forEach((shape) => {
        const animate = animateCard(shape)
        shape.addEventListener('mouseenter', () => animate.play())
        shape.addEventListener('mouseleave', () => animate.reverse())
      })
    },
  },

  mounted() {
    // setTimeout(() => {
    this.animate()
    // }, 500)
    this.animateHoverShapes()
  },
}
</script>

<style></style>
