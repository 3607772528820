<template>
  <v-card
    class="d-flex flex-column rounded-xl ma-md-10 project-card"
    color="#191919"
    flat
    :href="url"
    target="_blank"
  >
    <div class="gradient-1"></div>
    <div class="gradient-2"></div>
    <v-img :src="image" class="project-image" width="600px" contain></v-img>
    <v-card-title
      class="mt-auto text-h6 text-lg-h4 text-xl-h2 font-weight-bold"
      style="color: #efe0e0"
    >
      {{ title }}
    </v-card-title>
    <v-card-text
      class="text-body-1 text-lg-body-1 font-weight-bold"
      style="color: #9895da"
    >
      {{ text }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Project',

  props: {
    image: { type: String, default: null },
    title: { type: String, default: null },
    text: { type: String, default: null },
    url: { type: String, default: null },
  },

  data() {
    return {
      //
    }
  },
}
</script>

<style>
.project-card {
  position: relative;
  height: 500px;
  overflow: hidden;
}

.project-card .v-card__text,
.project-card .v-card__title {
  z-index: 2;
}

.project-image {
  z-index: 1;
  position: absolute;
  top: 0.5rem;
  left: 3rem;
  transform: rotate(10deg) scale(1);
  transition: 0.5s linear;
}

.project-card:hover .project-image {
  transform: rotate(5deg) scale(0.8);
  transition: 0.3s linear;
}

.gradient-1 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #254064 0,
    rgba(32, 32, 32, 0) 100%
  );
  width: 600px;
  height: 600px;
  position: absolute;
  top: 300px;
  right: 200px;
  transition: 0.5s ease-in-out;
}

.project-card:hover .gradient-1 {
  transform: translate(200px, -50px);
  transition: 0.5s ease-in-out;
}

.gradient-2 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #784f94 0,
    rgba(32, 32, 32, 0) 100%
  );
  width: 1000px;
  height: 600px;
  position: absolute;
  top: -200px;
  right: -200px;
  transition: 0.5s ease-in-out;
}

.project-card:hover .gradient-2 {
  transform: translate(-100px, -150px);
  transition: 0.5s ease-in-out;
}
</style>
